@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
:root {
  --primary-color: #212e3d;
  --text-color: #c7d0cc;
  --downy-color: #6dcac8;
  --indigo-color: #5977be;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--text-color);
  user-select: none;
  background-image: radial-gradient(circle, #344c67 20%, #212e3d 100%);
}

#root {
  height: 100%;
  width: 100%;
  max-width: 1000px;
}
